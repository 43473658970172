<template>
  <reports :pageOptions="pageOptions"></reports>
</template>

<script>
import Reports from "@/components/occupancy/reports/Reports";

export default {
  name: "MinAverageMax",
  components: {
    Reports,
  },
  data() {
    return {
      pageOptions: {
        pageTitle: "reports.reports.auto-release",
        searchRouteName: "r_occupancy-min-average-max-search-filters",
        filtersSection: "min_max_average",
        barWidth: 100,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              display: false,
            },
            x: {
              stacked: true,
            },
          },
        },
        chartOptionsY: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              ticks: {
                callback: function(value) {
                  return value;
                },
              },
            },
          },
        },
        legendLabels: ["maximum-value", "average-value", "minimum-value"],
      },
    };
  },
};
</script>
